import { referralDecryptoUserId } from 'utils/referalUidEncryption';

export const isNewReferralLandingPage = () => {
  if (typeof window !== 'undefined') {
    return window.location.pathname.indexOf('/i/ref') >= 0;
  }
  return false;
};

export const getNewReferralInviterId = () => {
  const locationPath = window.location.pathname;
  const reftIndex = locationPath.lastIndexOf('/i/reft/') + 8;
  const refIndex = locationPath.lastIndexOf('/i/ref/') + 7;

  // url添加语言层级后，pathname发生变化，split后找不到refid了
  if (locationPath.indexOf('/i/reft/') >= 0) {
    // teacher referral landing page
    // return referralDecryptoUserId(`${window.location.pathname.split('/')[3]}`);
    return referralDecryptoUserId(locationPath.substring(reftIndex));
  } else {
    // normal referral landing page
    // return referralDecryptoUserId(`${window.location.pathname.split('/')[3]}`);
    return referralDecryptoUserId(locationPath.substring(refIndex));
  }
};
