import * as React from 'react';
import { useRouter } from 'next/router';
import { isNewReferralLandingPage, getNewReferralInviterId } from 'components/referral/switcher';

function RefCode() {
  const router = useRouter();

  React.useEffect(() => {
    const refJSON = window?.localStorage?.getItem('kp.ref');

    if (router.query.ref && Array.isArray(router.query.ref) && !!router.query.ref[0]) {
      window?.localStorage?.setItem(
        'kp.ref',
        JSON.stringify({
          http_referer: document.referrer,
          landing_page: window.location.href,
          ref: isNewReferralLandingPage() ? getNewReferralInviterId() : router.query.ref[0],
          utm_source: router.query.utm_source,
          utm_medium: router.query.utm_medium,
          utm_campaign: router.query.utm_campaign,
          utm_term: router.query.utm_term,
          utm_content: router.query.utm_content,
        }),
      );
    } else if (!!refJSON) {
      const refObj = JSON.parse(refJSON);
      // if(!refObj.ref) {
      //   window?.localStorage?.setItem(
      //     'kp.ref',
      //     JSON.stringify({
      //       http_referer: document.referrer,
      //       landing_page: window.location.href,
      //       ref: isNewReferralLandingPage() ? getNewReferralInviterId() : router.query.ref,
      //       utm_source: router.query.utm_source,
      //       utm_medium: router.query.utm_medium,
      //       utm_campaign: router.query.utm_campaign,
      //       utm_term: router.query.utm_term,
      //       utm_content: router.query.utm_content,
      //     }),
      //   );
      // } else {
      window?.localStorage?.setItem(
        'kp.ref',
        JSON.stringify({
          http_referer: document.referrer,
          landing_page: window.location.href,
          ...refObj,
        }),
      );
      // }
    } else {
      window?.localStorage?.setItem(
        'kp.ref',
        JSON.stringify({
          http_referer: document.referrer,
          landing_page: window.location.href,
          ref: isNewReferralLandingPage() ? getNewReferralInviterId() : router.query.ref,
          utm_source: router.query.utm_source,
          utm_medium: router.query.utm_medium,
          utm_campaign: router.query.utm_campaign,
          utm_term: router.query.utm_term,
          utm_content: router.query.utm_content,
        }),
      );
    }

    if (!!router.query.utm_campaign) {
      if (Array.isArray(router.query.utm_campaign)) {
        window?.localStorage?.setItem('kp.utm_campaign', router.query.utm_campaign[0]);
      } else {
        window?.localStorage?.setItem('kp.utm_campaign', router.query.utm_campaign);
      }
    }
  }, []);

  return null;
}

export default RefCode;
