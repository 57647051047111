const REPLACE_RULE: any = { '1': 'A', '2': 'B', '3': 'C', '4': 'D', '5': 'E', '7': 'F', '8': 'G', '9': 'H' };
const REPLACE_RULE_KEYS: any = ['1', '2', '3', '4', '5', '7', '8', '9'];
const REPLACE_RULE_VALUES: any = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

export const referralEncryptoUserId = (uid: number) => {
  const hexUid = uid.toString(16).toLowerCase();
  const hexUid2 = hexUid.split('').map((hexCode: string) => {
    if (REPLACE_RULE[`${hexCode}`]) {
      return REPLACE_RULE[`${hexCode}`];
    } else {
      return hexCode;
    }
  });
  return hexUid2.join('');
};
export const referralDecryptoUserId = (uid: string) => {
  const decryptedUid = uid.split('').map((hexCode) => {
    if (REPLACE_RULE_VALUES.indexOf(hexCode) >= 0) {
      return REPLACE_RULE_KEYS[REPLACE_RULE_VALUES.indexOf(hexCode)];
    } else {
      return hexCode;
    }
  });
  return parseInt(decryptedUid.join(''), 16);
};

// copy from nile

// REPLACE_RULE = {"1": "A", "2": "B", "3": "C", "4": "D", "5": "E", "7": "F", "8": "G", "9": "H"}

// def referral_encrypto_user_id(user_id):
//     """
//     转十六进制后，数字替换为大写字母，小写字母不变
//     """
//     hex_user_id = hex(int(user_id))
//     hex_str = hex_user_id[2:]
//     encrypto_str = ""
//     for i in hex_str:
//         if i in REPLACE_RULE.keys():
//             encrypto_str += REPLACE_RULE[i]
//         else:
//             encrypto_str += i
//     return encrypto_str

// def referral_decrypto_user_id(referrer_id):
//     reverse_rule = dict((value, key) for key, value in REPLACE_RULE.items())
//     hex_str = ""
//     for i in referrer_id:
//         if i in reverse_rule.keys():
//             hex_str += reverse_rule[i]
//         else:
//             hex_str += i

//     hex_user_id = '0x' + hex_str
//     user_id = 0
//     try:
//         user_id = int(hex_user_id, 16)
//     except Exception:
//         pass
//     return user_id

/**
 * 生成 referral code
 * @added https://italki.atlassian.net/browse/MKP-9394
 * @param uid
 * @returns
 */
export function generateReferralCode(uid: number) {
  return referralEncryptoUserId(uid)
}
